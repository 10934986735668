.container {
  padding-top: 122px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000 url('/Background.png') no-repeat center;
  background-size: cover;
}

.subtitle {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  position: absolute;
  bottom: 10px;
  letter-spacing: -0.03em;
  color: #fff;
}

.main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.titles {
  display: flex;
  align-items: center;
  gap: 20px;
}

.titleImg {
  width: 56px;
  height: 56px;
}

.title {
  font-weight: 500;
  font-size: 36px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #fff;
}

@media only screen and (max-width: 1200px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .container {
    padding: 40px;
  }

  .title {
    font-size: 35px;
  }
}

@media only screen and (max-width: 600px) {
  .header img {
    width: 40px;
    height: 40px;
  }

  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 14px;
    text-align: center;
  }

  .container {
    padding: 20px;
  }
}
